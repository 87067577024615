<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Coupon List"/>

    <base-material-card
      icon="mdi-qrcode"
      title="Coupons List"
      class="px-5 py-3"
    >
      <v-simple-table>
        <filter-list
          :filters="filter"
          :on-change="getCoupons">
        </filter-list>
        <tbody>
        <tr v-for="coupon in coupons">
          <td>{{ coupon.id }}</td>
          <td @click="toggleCode(coupon)">{{ coupon.show_code ? coupon.code : '####'}}</td>
          <td>{{ coupon.duration.duration | dateDuration}}</td>
          <td>{{ coupon.start_date }}</td>
          <td>{{ coupon.end_date }}</td>
          <td>{{ coupon.created_at }}</td>
          <td>{{ coupon.user !== null ?  coupon.user.username : 'Unkown' }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-if="pagination.total > 10"
        v-model="pagination.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getCoupons"
        @next="getCoupons"
        @previous="getCoupons"/>
    </div>
    <div class="py-3"/>
    <v-btn x-large fab dark fixed right bottom color="blue"  to="/coupons/create">
      <v-icon large>mdi-plus</v-icon>
    </v-btn>

  </v-container>
</template>


<script>

  import FilterList from "../../components/base/FilterList";
  export default {
    name: "CouponsList",
    components: {FilterList},
    created() {
      this.getUrlParams();
      this.getCoupons();
    },
    watch: {
      $route() {
        this.getUrlParams();
        this.getCoupons();
      }
    },
    data() {
      return {
        coupons: [],
        pagination: {
          page: 1,
          length: 0,
          total: 0
        },
        query: {
          page: 1
        },
        filter: [
          {
            name: "page",
            value: 1,
            type: "pagination"
          },
          {
            name: "id",
            value: "",
            type: "text_field"
          },
          {
            name: "code",
            value: "",
            type: "text_field"
          },
          {
            name: "duration",
            value: "",
            type: "text_field",
          },
          {
            name: "start_date",
            value: "",
            type: "text_field"
          },
          {
            name: "start_date",
            value: "",
            type: "text_field"
          },
          {
            name: "created_at",
            value: "",
            type: "text_field"
          },
          {
            name: "created_by",
            value: "",
            type: "text_field"
          },
        ]
      }
    },
    methods: {
      getCoupons() {
        this.$authApi.get(`/coupons`, {
          params: this.query
        }).then(res => {
          let coupons = res.data.data.data;
          coupons.forEach(function (element) {
            element.show_code = false;
          });
          this.coupons = coupons;

          this.pagination.length = res.data.data.last_page;
          this.pagination.total = res.data.data.total;
        })
      },
      getUrlParams() {
        if (!Object.keys(this.$route.query).length) {
          this.query = {
            page: 1
          };
          this.filter = [
            {
              name: "page",
              value: 1,
              type: "pagination"
            },
            {
              name: "id",
              value: "",
              type: "text_field"
            },
            {
              name: "code",
              value: "",
              type: "text_field"
            },
            {
              name: "duration",
              value: "",
              type: "text_field",
            },
            {
              name: "start_date",
              value: "",
              type: "text_field"
            },
            {
              name: "end_date",
              value: "",
              type: "text_field"
            },
            {
              name: "created_at",
              value: "",
              type: "text_field"
            },
            {
              name: "created_by",
              value: "",
              type: "text_field"
            },
          ];
          this.$root.$emit('refreshParams', this.filter)
        } else {
          this.query = this.$route.query;
          this.filter.forEach(param => {
            param.value = this.query[param['name']];
          });
        }
      },
      toggleCode(coupon){
        coupon.show_code = !coupon.show_code;
      }

    }
  }
</script>
